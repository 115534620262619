<template>
    <div class='abilityDetails'>
        <div class="content">
            <!-- 折线图 -->
            <div class="echart">
                <div id="BrokenLine" class="histogram"></div>
                <div class="timenode">
                    <div v-for="(item, index) in nodeData" :key="index">{{item}}</div>
                </div>
            </div>
            <!-- 时间段选择 -->
            <selectTimePeriod :color="colorData" @changeItem="changeItem"></selectTimePeriod>

            <!-- 提示 -->
            <div class="tips">
                <div class="tipItem" v-for="(item, index) in tipsData" :key="index">
                    <div class="tipLeft">
                        <div class="tipIcon" :style="{'backgroundColor': item.color}"></div>
                        <div>{{ item.title }}</div>
                    </div>
                    <div>{{ item.value }}%</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import selectTimePeriod from './components/selectTimePeriod.vue'
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
])

export default {
  components: {
    selectTimePeriod
  },
  data () {
    return {
      current: `${new Date().getHours()}:${new Date().getMinutes()}`,
      nodeData: ['2022-12-07', '2022-12-10', '2022-12-13'],
      colorData: {
        color: '#6853C8',
        bagColor: '#EFEDF9'
      },
      tipsData: [
        {
          title: '深睡',
          value: '48',
          color: '#D330FF'
        },
        {
          title: '清醒',
          value: '9',
          color: '#FCB334'
        },
        {
          title: '浅睡',
          value: '43',
          color: '#E6B3F5'
        }
      ]
    }
  },
  props: {

  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.initializationBrokenLine()
    })
  },
  methods: {
    // 初始化折线图
    initializationBrokenLine () {
      if (!document.getElementById('BrokenLine')) {
        return
      }
      echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        VisualMapComponent,
        LineChart,
        CanvasRenderer,
        UniversalTransition
      ])
      var chartDom = document.getElementById('BrokenLine')
      var myChart = echarts.init(chartDom)
      var option
      const data = [
        { time: '2022-12-07', value: '5' },
        { time: '2022-12-07', value: '4' },
        { time: '2022-12-07', value: '7.5' },
        { time: '2022-12-07', value: '0' },
        { time: '2022-12-07', value: '2' }
      ]
      option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return '<div style="display: flex;align-content: center;">' +
                                    '<div style="color: #4F4F4F;font-weight: 700;">' + params[0].data.value + '</div>' +
                                    '<div>小时</div>' +
                                '</div>' +
                                '<div>' + params[0].data.time + '</div>'
          }
        },
        xAxis: {
          type: 'category',
          show: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false }// 去除网格线
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 5
        },
        grid: {
          left: '3%',
          bottom: '20%',
          containLabel: true
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#6853C8'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D0C9ED'
                },
                {
                  offset: 1,
                  color: '#EFEDF9'
                }
              ])
            },
            data: data
          }
        ]
      }

      option && myChart.setOption(option)
    },
    // 选择时间段监听
    changeItem (data) {
      console.log(data)
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/monthlyTrend.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;
    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        padding: 0 20px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .echart {
            width: 100%;
            position: relative;
            .histogram {
                width: 100%;
                height: 240px;
                /deep/ canvas {
                    width: 110% !important;
                    height: 110% !important;
                    left: -2% !important;
                }
            }
            .timenode {
                width: 90%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 25px;
                right: 0;
                color: #808080;
            }
        }
        .tips {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            color: #808080;
            margin-top: 20px;
            font-size: 14px;
            .tipItem {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                margin-top: 10px;
                .tipLeft {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .tipIcon {
                        width: 5px;
                        height: 10px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
</style>
